.loginBodyContainer {
    display: flex;
    flex-direction: row;
    height: 100vh;
    text-align: left;
    overflow-x: hidden; }

.leftSideDiv {
    position: relative;
    width: 40%;
    height: 100%;
    background-color: #f6f6f6; }

.rightSideDiv {
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
    z-index: 100; }

.inputFieldDiv {
    margin-bottom: 5px;
    width: 350px;
    flex-direction: row; }
.formContainer {
    width: 100%; }

.loginFormContainer {
    width: 60%; }

@media (min-width: 100px) and (max-width: 426px) {

    .loginBodyContainer {
        flex-direction: column;
        align-items: center; }

    .leftSideDiv {
        display: none; }

    .rightSideDiv {
        width: 80%;
 } }        // margin-left: 15px
//Bigger phones
@media (min-width: 426px) and (max-width: 660px) {

    .loginBodyContainer {
        flex-direction: column; }

    .rightSideDiv {
        // margin-left: 15px
        width: 80%; } }

//Tablets, medium devices
@media (min-width: 768px) and (max-width: 1024px) {

    .loginBodyContainer {
        flex-direction: row; } }

//Tablets, medium devices
@media (min-width: 1024px) and (max-width: 1380px) {

    .loginBodyContainer {
        flex-direction: row; } }

body {
    margin: 0;
    padding: 0; }
