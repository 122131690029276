.loginBodyContainer {
    display: flex;
    flex-direction: row;
    height: 100vh;
    text-align: left;
    overflow-x: hidden;
    overflow-y: hidden; }
.leftSideDiv {
    position: relative;
    width: 40%;
    height: 100%;
    background-color: #f6f6f6; }

.carexLogoContainer0 {
    margin-top: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
    display: none; }

.carexLogoContainer1 {
    margin-top: 30px;
    margin-left: 40px; }

.carexLogo {
    min-width: 200px;
    padding-bottom: 20px;
    margin: 0; }

.blue-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1100px;
    transform: translate(-50%, -50%);
    animation: rotate 5s linear infinite; }

.centeredText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    text-align: center;
    padding: 20px; }
.centeredText--main {
    font-family: Inter;
    font-size: 40px; }
.centeredText--secondary {
    font-family: Inter;
    font-size: 20px; }

.carexLogoText {
    font-family: Roboto, sans-serif;
    color: #707070;
    font-size: 14px;
    font-weight: 400; }

.loginImageContainer {
    display: flex;
    height: 100%;
    margin-top: -100px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative; }

.loginImage {
    width: auto;
    margin-right: -80px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block; }

.loginFormContainer {
    width: 60%; }

//Animations
@keyframes rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg); }
    to {
        transform: translate(-50%, -50%) rotate(360deg); } }

//Phones, small devices
@media (min-width: 100px) and (max-width: 426px) {

    .carexLogoContainer0 {
        display: block;
        height: fit-content;
        margin-left: 0; }

    .loginBodyContainer {
        flex-direction: column;
        align-items: center; }

    .leftSideDiv {
        display: none; }

    .loginFormContainer {
        width: 90%;
        justify-content: center;
        align-items: center; }

    .inputFieldDiv {
        flex-wrap: wrap;
        width: 80%; } }

//Bigger phones
@media (min-width: 426px) and (max-width: 660px) {
    .carexLogoContainer0 {
        display: block;
        height: fit-content; }

    .inputFieldDiv {
        flex-wrap: wrap;
        width: 80%; }

    .leftSideDiv {
        //width: 0%;
        display: none; }
    // margin-right: 20px;

    .rightSideDiv {
        width: 100%; }

    .loginBodyContainer {
        flex-direction: column;
        align-items: center; } }

//Bigger phones
@media (min-width: 660px) and (max-width: 768px) {
    .carexLogoContainer0 {
        display: none; }

    .inputFieldDiv {
        flex-wrap: wrap;
        width: 80%; } }

//Tablets, medium devices
@media (min-width: 768px) and (max-width: 1024px) {
    .carexLogoContainer0 {
        display: none; }

    .inputFieldDiv {
        flex-wrap: wrap;
        width: 80%; }

    .loginBodyContainer {
        flex-direction: row; } }

//Tablets, medium devices
@media (min-width: 1024px) and (max-width: 1380px) {
    .carexLogoContainer0 {
        display: none; }

    .inputFieldDiv {
        flex-wrap: wrap;
        width: 80%; }

    .loginBodyContainer {
        flex-direction: row; } }
